import React from 'react';
import PortableText from 'react-portable-text';

import {
	Grid,
	Container,
	Typography,
	makeStyles,
  Button,
} from '@material-ui/core';
import { HighlightCard } from '../../HighlightCard';

const useStyles = makeStyles((theme) => ({
  buildBusiness: {
    margin: '5rem 0 6rem',
    textAlign: 'center',
  },
  header: {
		color: '#ffffff',
    fontWeight: 700,
    fontSize: '2.5rem',
    lineHeight: 1.167,
    marginBottom: '3rem',
	},
  subHeader: {
		color: '#ffffff',
    fontWeight: 400,
    fontSize: '1.5rem',
    lineHeight: 1.5,
	},
  button: {
    margin: '3rem 0'
  },
  ctaDisclaimer: {
		color: '#ffffff',
    fontWeight: 300,
    fontSize: '.8rem',
    lineHeight: 1.5,
  },
  ctaLink: {
    textDecoration: 'none',
  },
  cardRow: {
    [theme.breakpoints.down('xs')]: {
			marginTop: '-4rem',
		},
  }
}));


export const Benefits = ({ 
  cards, 
  header, 
  subheader, 
  ctaText,
  _rawCtaDisclaimer,
  setModalOpen
 }) => {

  const classes = useStyles();

	return (
		<Container>
			<Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
				spacing={4}
        className={classes.cardRow}>
				{cards.map((card, index) => (
					<HighlightCard key={index} card={card} />
				))}
			</Grid>
      <Grid
				container
				direction='row'
				justifyContent='center'
				alignItems='center'
        className={classes.buildBusiness}>
          <Grid item xs={12} md={10}>
            <Typography variant='h2' className={classes.header}>
              {header}
            </Typography>
          </Grid>
          <Grid container item xs={12} md={8} lg={5}>
            <Typography variant='h5' className={classes.subHeader}>
              {subheader}
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} className={classes.button}>
            <iframe
              log 
              style={{ width: '100%', height: '75vh', border: 0, margin: 0, padding: 0, overflow: 'hidden' }} 
              src='https://secure.under.io/2461aa39-98ac-431f-8d35-f1621bc3eb10'></iframe>
            {/* <Button
              variant='contained'
              size='large'
              color='secondary'
              className={classes.ctaLink}
              onClick={() => setModalOpen(true)}>
              {ctaText}
            </Button> */}
          </Grid>
          <Grid container 
            item 
            xs={12} 
            md={10} 
            justifyContent= 'center'
            alignItems='center'
            >
            <PortableText
              content={_rawCtaDisclaimer}
              serializers={{
                normal: ({ children }) => (
                  <Typography variant='body1' className={classes.ctaDisclaimer}>
                    {children}
                  </Typography>
                ),
              }}
            />
          </Grid>
      </Grid>
		</Container>
	);
};
