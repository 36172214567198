import React from 'react';
import PortableText from 'react-portable-text';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { makeStyles } from '@material-ui/core/styles';
import { Dialog, Backdrop, Grid, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: '4rem',
	},
	modalGrid: {
		height: '90vh',
		width: '30vw',
		[theme.breakpoints.down('md')]: {
			width: '55vw',
		},
		[theme.breakpoints.down('sm')]: {
			width: '84vw',
		},
		[theme.breakpoints.down('xs')]: {
			width: '90vw',
		},
	},
	closeIcon: {
		color: theme.workwaveBlue,
		fontSize: 30,
		margin: '1rem',
		marginBottom: 0,
		position: 'relative',
		transition: 'all .3s ease-out',
		opacity: '0.8',
		'&:hover': {
			cursor: 'pointer',
			opacity: '1',
			transform: 'rotate(180deg) ',
		},
	},
	copyContainer: {
		padding: '1rem 2rem 0',
		minHeight: '100%',
		minWidth: '100%',
		textAlign: 'center',
	},
	ctaButton: {
		textTransform: 'uppercase',
		margin: '2rem 0',
		textDecoration: 'none',
	},
}));

export const BenefitsModal = ({
	open,
	setBenefitsModalOpen,
	_rawBenefitsModalCopy,
	benefitsModalCTALink,
	benefitsModalCTAText,
}) => {
	const classes = useStyles();

	return (
		open && (
			<div>
				<Dialog
					aria-labelledby='spring-modal-title'
					aria-describedby='spring-modal-description'
					className={classes.modal}
					maxWidth='md'
					open={open}
					onClose={(e) => setBenefitsModalOpen(false)}
					BackdropComponent={Backdrop}>
					<Grid container item direction='row' justify='flex-end'>
						<FontAwesomeIcon
							icon={['fas', 'xmark']}
							size='1x'
							onClick={(e) => setBenefitsModalOpen(false)}
							className={classes.closeIcon}
						/>
					</Grid>
					<Grid
						container
						direction='row'
						justifyContent='center'
						alignItems='center'
						item
						className={classes.modalGrid}
						xs>
						<div className={classes.copyContainer}>
							<PortableText
								content={_rawBenefitsModalCopy}
								serializers={{
									normal: ({ children }) => (
										<Typography variant='body1'>{children}</Typography>
									),
									strong: ({ children }) => (
										<Typography
											variant='body1'
											style={{ paddingTop: '1rem', fontWeight: 700 }}>
											{children}
										</Typography>
									),
								}}
							/>
							<a
								href={benefitsModalCTALink}
								target='_blank'
								rel='noreferrer'
								onClick={(e) => setBenefitsModalOpen(false)}>
								<Button
									variant='contained'
									size='medium'
									color='primary'
									className={classes.ctaButton}>
									{benefitsModalCTAText}
								</Button>
							</a>
						</div>
					</Grid>
				</Dialog>
			</div>
		)
	);
};
