import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
// import ReactPlayer from 'react-player/lazy';
import PortableText from 'react-portable-text';
import loadable from '@loadable/component';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ListItem } from '../../CustomStyles/Typography';

const Video = loadable(() => import('../../Home/Hero/Video'));

const useStyles = makeStyles((theme) => ({
	firstLine: {
		color: '#2a7abc',
		fontSize: '2.5rem',
		fontWeight: 700,
		textTransform: 'uppercase',
	},
	secondLine: {
		fontSize: '2rem',
		fontWeight: 700,
		color: theme.workwaveBlue,
		textTransform: 'none',
	},
	header: {
		fontWeight: 700,
		color: theme.workwaveBlue,
		fontSize: '2.0243rem',
		[theme.breakpoints.down('md')]: {
			fontSize: '1.8219rem',
		},
		[theme.breakpoints.down('sm')]: {
			fontSize: '1.5625rem',
		},
	},
	listStyle: {
		listStyleType: 'none',
		display: 'flex',
	},
	icon: {
		marginTop: '4px',
		marginRight: '12px',
		[theme.breakpoints.down('md')]: {},
	},
	// subHeader: {
	// 	fontWeight: 400,
	// 	color: theme.workwaveBlue,
	// 	fontSize: '1.25rem',
	// },
	playerWrapper: {
		position: 'relative',
		paddingTop: '56.25%',
		minHeight: '100%',
		minWidth: '100%',
		border: '1px solid #d8dee0',
	},
	reactPlayer: {
		position: 'absolute',
		top: 0,
		left: 0,
	},
	img: {
		maxWidth: '580px',
		marginTop: '-16em',
		// border: '1px solid #d8dee0',
		// borderRadius: '8px',
		// maxWidth: '600px',
		maxHeight: '550px',
		[theme.breakpoints.down('lg')]: {
			marginTop: '-25em',
		},
		[theme.breakpoints.down('md')]: {
			maxWidth: '504px',
		},
		[theme.breakpoints.down('sm')]: {
			marginTop: '-9em',
		},
		// [theme.breakpoints.up('xs')]: {
		// 	maxWidth: '295px',
		// },
	},
	img2: {
		maxWidth: '580px',
		maxHeight: '550px',
		[theme.breakpoints.down('md')]: {
			maxWidth: '504px',
		},
	},
}));

export const Feature = ({ feature, index }) => {
	const classes = useStyles();
	const { bodyHeader, image, videoVariant, _rawContent } = feature;

	const lg = useMediaQuery('(max-width: 1280px)');
	const md = useMediaQuery('(max-width: 960px)');
	const sm = useMediaQuery('(max-width: 600px)');

	return (
		<Grid
			style={{
				padding:
					index === 0
						? '4rem 0'
						: sm
						? '2rem 0 6rem'
						: md || lg
						? '2rem 0 4rem'
						: '2rem 0',
			}}
			container
			spacing={4}
			justifyContent='space-between'
			alignItems='center'
			direction={index === 0 ? 'row-reverse' : 'row'}>
			<Grid item xs={12} md={6} container justifyContent='center'>
				{image ? (
					<GatsbyImage
						image={image.asset?.gatsbyImageData}
						alt='chart'
						className={index === 0 ? classes.img : classes.img2}
						imgStyle={{ objectFit: 'contain', transition: '.5s' }}
					/>
				) : videoVariant ? (
					<div className={classes.playerWrapper}>
						{/* <ReactPlayer
							url={videoVariant}
							className={classes.reactPlayer}
							height='100%'
							width='100%'
							controls={true}
						/> */}
						<Video
							url={videoVariant}
							className={classes.reactPlayer}
							height='100%'
							width='100%'
							controls={true}
						/>
					</div>
				) : null}
			</Grid>
			<Grid
				container
				item
				xs={12}
				md={6}
				direction='column'
				justifyContent='flex-start'
				alignItems='flex-start'>
				<Typography variant='h2' className={classes.header}>
					{bodyHeader}
				</Typography>
				<Grid
					item
					container
					direction='column'
					alignItems='flex-start'
					justifyContent='space-evenly'>
					{_rawContent.map((content, index) => (
						<PortableText
							key={index}
							content={content}
							// className={classes.text}
							serializers={{
								h2: ({ children }) => (
									<Typography
										variant='h2'
										color='primary'
										className={classes.firstLine}
										style={{ lineHeight: '1.0' }}>
										{children}
									</Typography>
								),
								strong: ({ children }) => (
									<span className={classes.secondLine}>{children}</span>
								),
								normal: ({ children }) => (
									<Typography
										variant='body1'
										style={{
											color: '#4B5B69',
											fontFamily: 'Roboto',
											marginTop: '1rem',
										}}>
										{children}
									</Typography>
								),
								li: ({ children }) => (
									<Grid item style={{ marginLeft: '-1.875rem' }}>
										<ListItem>
											<li className={classes.listStyle}>
												<Grid>
													<FontAwesomeIcon
														icon={['fas', 'circle-check']}
														style={{
															color: '#002D5C',
														}}
														className={classes.icon}
														size='lg'
													/>
												</Grid>
												{children}
											</li>
										</ListItem>
									</Grid>
								),
							}}
						/>
					))}
				</Grid>
			</Grid>
		</Grid>
	);
};
