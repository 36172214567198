import React, { useState, useContext } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';

import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Hero } from '../../components/FinancialServices/BBVisa/Hero';
import { WaveUpSVG } from '../../components/WaveSVGs/WaveUpSVG';
import { WaveDownSVG } from '../../components/WaveSVGs/WaveDownSVG';
import { SEO } from '../../components/SEO';
import { Feature } from '../../components/FinancialServices/BBVisa/Feature';
import { Benefits } from '../../components/FinancialServices/BBVisa/Benefits';
import { BenefitsModal } from '../../components/FinancialServices/BBVisa/Benefits/BenefitsModal';
import FormModalContext from '../../context/FormModalContext';

// import { Form } from '../../components/ContactForm/Form';

const Form = loadable(() => import('../../components/ContactForm/Form'));

const useStyles = makeStyles((theme) => ({
	text: {
		color: '#4B5B69',
		fontFamily: 'Roboto',
		lineHeight: 1.8,
		'& a': {
			color: theme.workwaveBlue,
		},
	},
	centerBackground: {
		background: theme.workwaveBlue,
		padding: '2rem 0 6rem',
	},
}));

const BusinessBuilderVisa = ({ data, location }) => {
	const visa = data.fs.edges[0].node.visa;
	const classes = useStyles();

	// stuff for modal window for 'Apply' button
	const [modalOpen, setModalOpen] = useState(false);

	const { formModalOpen } = useContext(FormModalContext);

	const {
		cardPageHero,
		metaDescription,
		metaTitle,
		cardPageFeatures,
		ctaCards,
		ctaHeader,
		ctaSubheader,
		ctaText,
		_rawCtaDisclaimer,
		_rawBenefitsModalCopy,
		benefitsModalCTALink,
		benefitsModalCTAText,
		contactForm,
		marketoId,
		pardotUrl,
	} = visa;
	return (
		<>
			<SEO title={metaTitle} description={metaDescription} />
			<Hero hero={cardPageHero} />
			<Container style={{ marginBottom: '15rem' }}>
				{cardPageFeatures.map((feature, index) => (
					<Feature feature={feature} index={index} />
				))}
			</Container>
			<div style={{ marginTop: '-18rem' }}>
				<WaveDownSVG height='213' width='100%' fill='white' />
			</div>
			<div className={classes.centerBackground}>
				<Benefits
					cards={ctaCards}
					header={ctaHeader}
					subheader={ctaSubheader}
					ctaText={ctaText}
					_rawCtaDisclaimer={_rawCtaDisclaimer}
					setModalOpen={setModalOpen}
				/>
			</div>{' '}
			{formModalOpen ? (
				<Form
					formId={marketoId}
					pardotUrl='https://go.workwave.com/l/1042281/2023-11-16/65swnn'
					contactForm={{
						header: contactForm.header,
						body: null,
					}}
					privacy
					noForm
					location={location}
				/>
			) : null}
			<div>
				<WaveUpSVG height='213' width='100%' fill='#f4f8ff' />
			</div>
			{/* <BenefitsModal
				open={modalOpen}
				setBenefitsModalOpen={setModalOpen}
				_rawBenefitsModalCopy={_rawBenefitsModalCopy}
				benefitsModalCTALink={benefitsModalCTALink}
				benefitsModalCTAText={benefitsModalCTAText}
			/> */}
		</>
	);
};

export const query = graphql`
	query FSVisaPageQuery {
		fs: allSanityFinancialServices {
			edges {
				node {
					visa: fsVisaPage {
						metaTitle
						metaDescription
						cardPageHero {
							backgroundImage {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							_rawContent
						}
						cardPageFeatures {
							title
							image {
								asset {
									gatsbyImageData(placeholder: BLURRED)
								}
							}
							_rawContent
						}
						ctaCards {
							faIcon
							header
							Body
							accentColor {
								hexValue
							}
						}
						ctaHeader
						ctaSubheader
						ctaText
						_rawCtaDisclaimer
						_rawBenefitsModalCopy
						benefitsModalCTALink
						benefitsModalCTAText
						contactForm {
							header
						}
						marketoId
						pardotUrl
					}
				}
			}
		}
	}
`;

export default BusinessBuilderVisa;
