import React from 'react';
import PortableText from 'react-portable-text';
import { BgImage } from 'gbimage-bridge';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Grid, Typography, Container, useMediaQuery } from '@material-ui/core';

import { WaveUpHero } from '../../../WaveSVGs/WaveUpHero';
import { HeroBody } from '../../../CustomStyles/Typography';

const useStyles = makeStyles((theme) => ({
	text: {
		color: theme.white,
		marginBottom: '1rem',
		[theme.breakpoints.down('sm')]: {
			textAlign: 'center',
		},
	},
	header: {
		lineHeight: 1.1,
		marginTop: '50px',
		[theme.breakpoints.down('xs')]: {
			fontSize: '2.2rem',
			marginTop: '20px',
		},
	},
	imgCont: {
		padding: '6rem 0',
		maxWidth: '100vw',
		height: '100%',
		overflowX: 'hidden',
		backgroundSize: 'cover',
		background:
			'linear-gradient(0deg, rgba(248, 251, 255, 0.8), rgba(248, 251, 255, 0.8)), linear-gradient(0deg, #F8FBFF, #F8FBFF), #F8FBFF',
		[theme.breakpoints.down('md')]: {},

		[theme.breakpoints.down('xs')]: {
			padding: '3rem 0',
			backgroundPosition: 'right',
		},
	},
}));

export const Hero = ({ hero }) => {
	const classes = useStyles();
	const lg = useMediaQuery('(max-width: 1280px)');
	const md = useMediaQuery('(max-width: 960px)');
	const theme = useTheme();
	// const accentColor = theme.lightBlue;

	return (
		<>
			<BgImage
				className={classes.imgCont}
				image={hero?.backgroundImage?.asset?.gatsbyImageData}
				style={{
					lineHeight: '0',
					overflow: 'hidden',
					paddingBottom: lg ? '2rem' : '1rem',
				}}>
				<Container fixed style={{ position: 'relative' }}>
					<Grid container direction='row' justifyContent='flex-start'>
						<Grid
							container
							item
							direction='column'
							justify='flex-start'
							alignItems={md ? 'center' : 'flex-start'}
							xs={12}
							md={10}>
							{hero?._rawContent.map((content, index) => (
								<PortableText
									key={index}
									content={content}
									className={classes.text}
									serializers={{
										h1: ({ children }) => (
											<Typography variant='h1' className={classes.header}>
												{children}
											</Typography>
										),
										h2: ({ children }) => (
											<Typography variant='h2' className={classes.header}>
												{children}
											</Typography>
										),
										h4: ({ children }) => (
											<Typography variant='h4' className={classes.header}>
												{children}
											</Typography>
										),
										normal: ({ children }) => <HeroBody>{children}</HeroBody>,
									}}
								/>
							))}{' '}
						</Grid>
					</Grid>
				</Container>
				<WaveUpHero height='213' width='100%' fill='white' />
			</BgImage>
		</>
	);
};
